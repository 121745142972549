import React from "react"
import Sound from "react-sound"

import playBtn from "../images/pauseplay.svg"
import pauseBtn from "../images/pauseplay.svg"
import replayBtn from "../images/replay2.svg"

function control(altText, btn, clickHandler) {
  const onClick = ev => {
    ev.preventDefault()
    clickHandler()
  }
  return (
    <div className="block my-1">
      <button onClick={onClick} className="bg-black h-16 w-16 py-2 px-3">
        <img src={btn} alt={altText} />
      </button>
    </div>
  )
}

export default class PlayerControls extends React.Component {
  render() {
    return <div>{this.renderControls()}</div>
  }

  renderControls() {
    const controls = {
      play: this.props.playStatus === Sound.status.STOPPED,
      stop: this.props.playStatus !== Sound.status.STOPPED,
      pause: this.props.playStatus === Sound.status.PLAYING,
      resume: this.props.playStatus === Sound.status.PAUSED,
    }

    return (
      <div>
        <div className="">
          {controls.play && control("Play", playBtn, this.props.onPlay)}
          {controls.pause && control("Pause", pauseBtn, this.props.onPause)}
          {controls.resume && control("Resume", playBtn, this.props.onResume)}
          {controls.stop && control("Restart", replayBtn, this.props.onRestart)}
          {controls.play && control("Restart", replayBtn, this.props.onRestart)}
        </div>
      </div>
    )
  }
}
