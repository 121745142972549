import React, { Component } from "react"
import Typed from "typed.js"

class Typer extends Component {
  componentDidMount() {
    // If you want to pass more options as props, simply add
    // your desired props to this destructuring assignment.
    const { strings } = this.props
    // You can pass other options here, such as typing speed, back speed, etc.
    const options = {
      strings: strings,
      typeSpeed: 30,
      backSpeed: 0,
      loop: false,
      contentType: "html",
    }

    // this.el refers to the <span> in the render() method
    this.typed = new Typed(this.el, options)

    if (this.typed) {
      this.props.onPlay && this.typed.start()
      this.props.onPause && this.typed.stop()
      this.props.onReset && this.typed.reset()
    }
  }
  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    if (this.typed) {
      this.typed.destroy()
    }
  }

  render() {
    const { strings } = this.props

    if (this.typed) {
      this.props.onPlay && this.typed.start()
      this.props.onPause && this.typed.stop()
      this.props.onReset && this.typed.reset()
    }

    //<span dangerouslySetInnerHTML={{__html: strings }} />
    return (
      <div className="wrap">
        <span
          ref={el => {
            this.el = el
          }}
        ></span>{" "}
      </div>
    )
  }
}

export default Typer
