import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { FacebookShareButton, TwitterShareButton } from "react-share"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Player from "../components/player"
import htbsEn from "../assets/htbs-en.mp3"
import htbsAm from "../assets/htbs-am.mp3"
import downArrow from "../images/downarrow.svg"
import TwitterIcon from "../images/twitter.svg"
import FacebookIcon from "../images/facebook.svg"
import downArrowActive from "../images/downarrow-active.svg"

const DestaStory = ({ intl }) => {
  let audioLang = intl.formatMessage({ id: "htbs" })
  let shareUrl = "heartheblindspot.org"

  const [isDesta, setView] = useState(false)
  const toggleView = () => setView(previousValue => !previousValue)

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "nav.alt.desta" })}
        keywords={[`Hear The Blind Spot`, `DestaStory`, `Together!`]}
      />
      <div className="container flex flex-wrap w-full">
        <div className="">
          <p className="digIncl">{intl.formatMessage({ id: "home.p1" })}</p>
          <p className="digBSpot">{intl.formatMessage({ id: "home.p2" })}</p>
        </div>
        <div className="flex py-4">
          <button
            onClick={toggleView}
            className="font-bold py-2 inline-flex items-center"
          >
            <img
              src={!isDesta ? downArrow : downArrowActive}
              className="h-12 sm:h-16 -ml-2"
              alt={intl.formatMessage({ id: "nav.alt.start" })}
            />
          </button>
        </div>
        <div className={isDesta ? `slideshow-item shown` : `slideshow-item`}>
          {isDesta && (
            <div className="destaScript">
              <Player
                scriptLines={[
                  intl.formatMessage({ id: "story.p1" }) +
                    intl.formatMessage({ id: "story.p2" }) +
                    intl.formatMessage({ id: "story.p3" }) +
                    intl.formatMessage({ id: "story.p4" }) +
                    intl.formatMessage({ id: "story.p5" }) +
                    intl.formatMessage({ id: "story.life-expectancy" }) +
                    intl.formatMessage({ id: "story.p6" }) +
                    intl.formatMessage({ id: "story.child-mortality" }) +
                    intl.formatMessage({ id: "story.p7" }) +
                    intl.formatMessage({ id: "story.malaria" }) +
                    intl.formatMessage({ id: "story.p8" }) +
                    intl.formatMessage({ id: "story.hiv" }) +
                    intl.formatMessage({ id: "story.p9" }) +
                    intl.formatMessage({ id: "story.p10" }) +
                    intl.formatMessage({ id: "story.incomes" }) +
                    intl.formatMessage({ id: "story.p11" }) +
                    intl.formatMessage({ id: "story.primary-schools" }) +
                    intl.formatMessage({ id: "story.p12" }) +
                    intl.formatMessage({ id: "story.internet-usage" }) +
                    intl.formatMessage({ id: "story.p13" }) +
                    intl.formatMessage({ id: "story.p14" }) +
                    intl.formatMessage({ id: "story.p15" }) +
                    intl.formatMessage({ id: "story.p16" }) +
                    intl.formatMessage({ id: "story.p17" }) +
                    intl.formatMessage({ id: "story.p18" }) +
                    intl.formatMessage({ id: "story.p19" }) +
                    intl.formatMessage({ id: "story.p20" }) +
                    intl.formatMessage({ id: "story.donate" }),
                ]}
                currentAudio={audioLang === "en" ? htbsEn : htbsAm}
              />

              <div className="mt-40 mb-5">
                <p className="shareStory md:w-4/5">
                  {intl.formatMessage({ id: "story.share" })}
                </p>
              </div>
              <div className="inline-flex py-2">
                <FacebookShareButton
                  quote={intl.formatMessage({ id: "social.share-text" })}
                  url={shareUrl}
                >
                  <img
                    src={FacebookIcon}
                    className="h-12 w-12 p-1"
                    alt={intl.formatMessage({ id: "social.share-text" })}
                  />
                </FacebookShareButton>

                <TwitterShareButton
                  url={shareUrl}
                  title={intl.formatMessage({ id: "social.share-text" })}
                >
                  <img
                    src={TwitterIcon}
                    className="h-12 w-12 p-1"
                    alt={intl.formatMessage({ id: "social.share-text" })}
                  />
                </TwitterShareButton>
              </div>
              <div className="flex items-start bg-black border-1 border-black w-12 h-1 my-4 ml-2"></div>
              <div className="py-4">
                <p className="storySources">
                  {intl.formatMessage({ id: "story.sources" })}
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={intl.formatMessage({ id: "story.datasheet" })}
                  className="underline storySources"
                >
                  {intl.formatMessage({ id: "story.data" })}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(DestaStory)
