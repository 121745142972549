import React, { Component } from "react"
import { injectIntl } from "gatsby-plugin-intl"

import DestaStory from "../components/destastory"
import HTBS from "../components/htbs"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = { showHome: true }
  }
  render() {
    return this.state.showHome ? <DestaStory /> : <HTBS />
  }
}
export default injectIntl(IndexPage)
