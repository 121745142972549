import React, { Component } from "react"
import Sound from "react-sound"
import { injectIntl } from "gatsby-plugin-intl"

import Typer from "./typer"
import PlayerControls from "./playercontrols"

class Player extends Component {
  constructor(props) {
    super(props)

    this.state = {
      typerStatus: "Paused",
      controlled: true,
      position: 50,
      volume: 100,
      playbackRate: 1,
      loop: false,
      playStatus: Sound.status.PLAYING,
      autoLoad: true,
    }
  }

  getStatusText() {
    switch (this.state.playStatus) {
      case Sound.status.PLAYING:
        return "playing"
      case Sound.status.PAUSED:
        return "paused"
      case Sound.status.STOPPED:
        return "stopped"
      default:
        return "(unknown)"
    }
  }

  render() {
    const { volume, playbackRate, loop } = this.state

    return (
      <div className="flex items-end">
        <div className="flex self-start w-3/4 mr-4">
          <Typer
            onPlay={this.state.playStatus === Sound.status.PLAYING}
            onPause={this.state.playStatus === Sound.status.PAUSED}
            onReset={this.state.typerStatus === "Reset"}
            strings={this.props.scriptLines}
          />
        </div>
        <div className="w-1/4">
          <div className="float-right">
            <div className="">
              <PlayerControls
                playStatus={this.state.playStatus}
                loop={loop}
                onPlay={() =>
                  this.setState({ playStatus: Sound.status.PLAYING })
                }
                onPause={() =>
                  this.setState({ playStatus: Sound.status.PAUSED })
                }
                onResume={() =>
                  this.setState({ playStatus: Sound.status.PLAYING })
                }
                onStop={() =>
                  this.setState({
                    playStatus: Sound.status.STOPPED,
                    position: 0,
                  })
                }
                onRestart={() =>
                  this.setState({
                    typerStatus: "Reset",
                    playStatus: Sound.status.PLAYING,
                    position: 0,
                  })
                }
                onSeek={position => this.setState({ position })}
                onVolumeUp={() =>
                  this.setState({
                    volume: volume >= 100 ? volume : volume + 10,
                  })
                }
                onVolumeDown={() =>
                  this.setState({ volume: volume <= 0 ? volume : volume - 10 })
                }
                onPlaybackRateUp={() =>
                  this.setState({
                    playbackRate:
                      playbackRate >= 4 ? playbackRate : playbackRate + 0.5,
                  })
                }
                onPlaybackRateDown={() =>
                  this.setState({
                    playbackRate:
                      playbackRate <= 0.5 ? playbackRate : playbackRate - 0.5,
                  })
                }
                onToggleLoop={e => this.setState({ loop: e.target.checked })}
                duration={
                  this.props.currentAudio ? this.props.currentAudio.duration : 0
                }
                position={this.state.position}
                playbackRate={playbackRate}
              />
              {this.props.currentAudio &&
                (this.state.controlled ? (
                  <Sound
                    url={this.props.currentAudio}
                    playStatus={this.state.playStatus}
                    position={this.state.position}
                    autoLoad={this.state.autoLoad}
                    volume={volume}
                    playbackRate={playbackRate}
                    loop={loop}
                    onLoading={({ bytesLoaded, bytesTotal }) =>
                      console.log(`${(bytesLoaded / bytesTotal) * 100}% loaded`)
                    }
                    onLoad={() => console.log("Loaded")}
                    onPlaying={({ position }) =>
                      this.setState({ typerStatus: "Playing", position })
                    }
                    onPause={() => this.setState({ typerStatus: "Paused" })}
                    onResume={() => this.setState({ typerStatus: "Resumed" })}
                    onStop={() => this.setState({ typerStatus: "Stop" })}
                    onFinishedPlaying={() =>
                      this.setState({ playStatus: Sound.status.STOPPED })
                    }
                  />
                ) : (
                  <Sound
                    url={this.props.currentAudio}
                    playStatus={this.state.playStatus}
                    playFromPosition={this.state.position}
                    autoLoad={this.state.autoLoad}
                    volume={volume}
                    playbackRate={playbackRate}
                    loop={loop}
                    onLoading={({ bytesLoaded, bytesTotal }) =>
                      console.log(`${(bytesLoaded / bytesTotal) * 100}% loaded`)
                    }
                    onLoad={() => console.log("Loaded")}
                    onPlaying={({ position }) =>
                      console.log("Position", position)
                    }
                    onPause={() => console.log("Paused")}
                    onResume={() => console.log("Resumed")}
                    onStop={() => console.log("Stopped")}
                    onFinishedPlaying={() =>
                      this.setState({ playStatus: Sound.status.STOPPED })
                    }
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(Player)
